import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import { useCtaRegistration } from '~hooks/useAuth';

const useStyles = makeStyles(theme => ({
  root: props => ({
    width: '100%',
    zIndex: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: props.compactMargins ? '36px' : '52px',
    padding: props.compactMargins ? '64px 20px 10px' : '100px 20px 10px',
    [theme.breakpoints.down('xs')]: {
      padding: '52px 20px 10px',
      gap: '32px'
    }
  }),
  textArea: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 16,
    display: 'inline-flex'
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '52px',
    fontWeight: 600,
    lineHeight: '118%',
    color: '#FFFFFF',
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '660px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '28px'
    }
  },
  text: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '140%',
    color: '#FFFFFF',
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '450px',
    textAlign: 'center'
  },
  sentText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '140%',
    color: '#FFFFFF',
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'center'
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    border: '1px solid #A7A7A7',
    borderRadius: 7,
    height: '45px',
    width: '100%'
  },
  emailInputRoot: {
    width: 'calc(100% - 193px)',
    fontSize: 16,
    lineHeight: '16px',
    height: '100%',
    backgroundColor: 'transparent',
    marginLeft: 19,
    color: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 100px)',
      fontSize: 14,
      lineHeight: '14px'
    }
  },
  button: {
    width: '193px',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '16px',
    backgroundColor: '#FFFFFF',
    margin: 'auto',
    marginTop: '-1px',
    marginRight: 0,
    height: '45px',
    whiteSpace: 'nowrap',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 7,
    borderBottomRightRadius: 7,
    textTransform: 'none',
    paddingLeft: '9px',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#ececec',
      color: '#000000'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '14px'
    }
  },
  errorText: {
    color: theme.palette.error.main
  },
  inputForm: {
    width: '100%',
    maxWidth: '650px'
  }
}));

const WatchFreeEmailRegister = ({ playlistUid, compactMargins }) => {
  const classes = useStyles({ compactMargins });

  const { isUserStateLoading, marketingUser } = useUserContext().session;
  const [showSlice, setShowSlice] = useState(false);

  const { register, handleSubmit } = useForm();
  const [regState, registerCta] = useCtaRegistration();
  const { isLoading, isError, error, data } = regState;

  const onSubmit = ({ username }) => {
    registerCta({ username, strategy: 'email-for-content', playlistUid });
  };

  useEffect(() => {
    /**
     * Here we are showing the slice if the user is a not logged in user once user state loading completes.
     * When user gets registered, user automatically getting logged in. At that time also we should show this slice.
     * Therefore, once show slice set to true, we should not make it false again.
     */
    if (!showSlice && !isUserStateLoading && marketingUser?.isGuestUser) {
      setShowSlice(true);
    }
  }, [showSlice, isUserStateLoading, marketingUser?.isGuestUser]);

  if (!showSlice) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.textArea}>
        <h3 className={classes.title}>Want to watch the full video for free?</h3>
        <p className={classes.text}>
          Enter your email and we’ll send you a link to watch the full series free.
        </p>
      </div>
      {!data ? (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.inputForm}>
          <div className={classes.inputContainer}>
            <InputBase
              placeholder="Enter Email Address"
              type="email"
              name="username"
              required
              inputProps={{ 'aria-label': 'enter email address' }}
              className={classes.emailInputRoot}
              inputRef={register}
              disabled={isLoading}
            />
            <Button className={classes.button} disableElevation type="submit" disabled={isLoading}>
              Submit
            </Button>
          </div>
          {isError && <small className={classes.errorText}>{error}</small>}
        </form>
      ) : (
        <p className={classes.sentText}>
          ✓ Email sent!
          <br />
          Please check your inbox for the access link.
        </p>
      )}
    </div>
  );
};

WatchFreeEmailRegister.propTypes = {
  playlistUid: PropTypes.string.isRequired,
  compactMargins: PropTypes.bool
};

WatchFreeEmailRegister.defaultProps = {
  compactMargins: false
};

export default WatchFreeEmailRegister;
